<template>
  <div class="services-content">
    <div class="header">
      <div class="text">
        <h1>SERVICES</h1>
      </div>
    </div>
    <div class="services">
      <div
        class="item"
        v-for="(item, index) in serviceList"
        :key="item.image"
        :class="{ gray: index % 2 == 0 }"
      >
        <div class="mainbox">
          <div class="img" v-show="index % 2 != 0" style="margin-right: 40px">
            <img :src="item.image" alt="" />
          </div>
          <div class="content">
            <div class="title">
              <h1>{{ item.title }}</h1>
            </div>
            <div class="info" v-html="item.content">
            </div>
          </div>
          <div class="img" v-show="index % 2 == 0">
            <img :src="item.image" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { serviceData } from "@/api/services";
export default {
  data() {
    return {
      serviceList: [],
    };
  },
  mounted() {
    this.getserviceData();
  },
  methods: {
    getserviceData() {
      serviceData().then((res) => {
        this.serviceList = res.data;
      });
    },
  },
};
</script>


